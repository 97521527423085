import { useRef, useState } from "react";
import AppleStoreIcon from "../../icons/AppleStoreIcon";
import GooglePlayIcon from "../../icons/GooglePlayIcon";
import { SCompFooter } from "./styles/SCompFooter";
import { SCompFooterContact } from "./styles/SCompFooterContact";
import { SCompFooterContactForm } from "./styles/SCompFooterContactForm";
import { SCompFooterContactFormInputCont } from "./styles/SCompFooterContactFormInputCont";
import { SCompFooterContactLabel } from "./styles/SCompFooterContactLabel";
import { SCompFooterContainer } from "./styles/SCompFooterContainer";
import { SCompFooterCopyrights } from "./styles/SCompFooterCopyrights";
import { SCompFooterLink } from "./styles/SCompFooterLink";
import { SCompFooterLinks } from "./styles/SCompFooterLinks";
import { SCompFooterLinksCont } from "./styles/SCompFooterLinksCont";
import { SCompFooterLinksTitle } from "./styles/SCompFooterLinksTitle";
import { SCompFooterSocial } from "./styles/SCompFooterSocial";
import { SCompFooterSocialBtn } from "./styles/SCompFooterSocialBtn";
import { SCompFooterWrapper } from "./styles/SCompFooterWrapper";
import styled from "styled-components";

const Footer = () => {
  const inputRef = useRef(null);
  const [message, setMessage] = useState("");
  const onSubmit = (e) => {
    e.preventDefault();
    fetch("https://blog.busyapp.com.sa/wp-json/wp/v2/newsletter/save", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: inputRef.current.value,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          setMessage("تم الإشتراك بنجاح");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const Email = styled.div`
    display: flex;
    align-items: center;
    gap: 2px;
    font-size: 16px;
    margin-top: 16px;
    color: rgb(128, 128, 128);
  `;
  const Link = styled.a`
    color: rgb(128, 128, 128);
    text-decoration: underline;
  `;
  return (
    <SCompFooter className="footer">
      <SCompFooterContainer>
        <SCompFooterWrapper>
          <SCompFooterLinksCont>
            <SCompFooterLinks>
              <SCompFooterLinksTitle>عن التطبيق</SCompFooterLinksTitle>
              <SCompFooterLink as="a" href={"/about-us"}>
                من نحن
              </SCompFooterLink>

              <SCompFooterLink as="a" href={"/privacy-policy"}>
                سياسة الخصوصية
              </SCompFooterLink>
              <SCompFooterLink as="a" href={"/contact-us"}>
                تواصل معنا
              </SCompFooterLink>
            </SCompFooterLinks>
          </SCompFooterLinksCont>
          <SCompFooterContact>
            {message || (
              <SCompFooterContactForm onSubmit={onSubmit}>
                <SCompFooterContactLabel htmlFor="email2">اشترك في القائمة البريدية</SCompFooterContactLabel>
                <div className="s-comp-footer-contact-form-input-cont">
                  <input
                    type="email"
                    id="email2"
                    name="email"
                    ref={inputRef}
                    placeholder="البريد الإلكتروني"
                    required
                  />
                  <button style={{ backgroundColor: "#0A1730" }}>أشترك الأن</button>
                </div>
              </SCompFooterContactForm>
            )}
            <SCompFooterSocial>
              <SCompFooterSocialBtn
                href="https://www.instagram.com/busyapp_sa?igsh=N2Y4cjVpMzUwaHZm&utm_source=qr"
                target="_blank"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path
                    d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"
                    fill="currentColor"
                  />
                </svg>
              </SCompFooterSocialBtn>
              <SCompFooterSocialBtn href="https://x.com/busyapp_sa" target="_blank">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10.5286 3.59033C10.5332 3.69593 10.5332 3.79673 10.5332 3.90233C10.5378 7.10394 8.18559 10.8 3.88396 10.8C2.61524 10.8 1.36967 10.4207 0.300049 9.71035C0.485264 9.73435 0.670479 9.74395 0.855695 9.74395C1.90679 9.74395 2.93011 9.37915 3.75894 8.70234C2.75878 8.68314 1.87901 8.00634 1.5734 7.01754C1.92531 7.08954 2.28648 7.07514 2.62913 6.97434C1.54099 6.75354 0.758457 5.75993 0.753826 4.60313C0.753826 4.59353 0.753826 4.58393 0.753826 4.57433C1.07795 4.76153 1.44375 4.86713 1.81418 4.87673C0.790869 4.16633 0.471373 2.75033 1.09184 1.64152C2.28185 3.15833 4.03214 4.07513 5.91207 4.17593C5.72223 3.33593 5.98153 2.45272 6.58811 1.85752C7.52807 0.94072 9.0098 0.98872 9.89883 1.96312C10.4221 1.85752 10.9268 1.65592 11.3852 1.37272C11.2092 1.93432 10.8434 2.40952 10.3572 2.71193C10.8203 2.65432 11.2741 2.52472 11.7 2.33272C11.3852 2.82233 10.987 3.24473 10.5286 3.59033Z"
                    fill="currentColor"
                  />
                </svg>
              </SCompFooterSocialBtn>
            </SCompFooterSocial>
            <Email>
              كن على تواصل:
              <Link href="mailto:info@busy.com.sa">info@busy.com.sa</Link>
            </Email>
          </SCompFooterContact>
        </SCompFooterWrapper>
      </SCompFooterContainer>
      {/*  <SCompFooterCopyrights>
        جميع الحقوق محفوظة 2023 © شركة تفاصيل الأفكار
        <span style={{ paddingRight: "8px" }}>All rights reserved 2023 © Details Digital SA</span>
      </SCompFooterCopyrights>*/}
    </SCompFooter>
  );
};

export default Footer;
