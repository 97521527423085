import React from "react";
import { Routes, Route } from "react-router-dom";

//components
import { GStyleBase } from "./constants/global-style";
import AboutUs from "./pages/about-us";
import BlogDetails from "./pages/blog-details";
import DownloadApp from "./pages/download-app";
import Blogs from "./pages/blogs";
import ContactUs from "./pages/contact-us";
import Home from "./pages/home";
import PageNotFound from "./pages/pageNotFound/PageNoFound";
import Footer from "./components/Footer";
import Header from "./components/Header";
import PrivacyPolicy from "./pages/faq";
import FaqDetails from "./pages/faq/FaqDetails";
import Privacy from "./pages/privacy-policy";
import AgentRegistration from "./pages/agent-registration";
import RegistrationForm from "./pages/registeration-from";
import whatsapp from "./assets/images/whatsapp-icon.png";
import styled from "styled-components";
import TermsConditions from "./pages/terms-conditions/TermsConditions";

const WhatsIconContainer = styled.div`
  position: fixed;
  right: 4%;
  bottom: 4%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .icon-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  img {
    width: 70px;
    height: 70px;
    border-radius: 100%;
    cursor: pointer;
    background: #fff;
  }

  .close-btn {
    position: absolute;
    top: -2px;
    right: -2px;
    width: 20px;
    height: 20px;
    border: none;
    border-radius: 50%;
    background: rgb(10, 23, 48);
    color: white;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }

  .aos-item {
    display: none;
  }

  &.active .aos-item {
    display: block;
    animation: fadeIn 0.5s ease;
  }
`;

function App() {
  return (
    <main>
      <GStyleBase />
      <Header />
      <WhatsIconContainer data-aos="fade-up">
        <div className="icon-wrapper">
          <a href="https://wa.me/966138113815" target="_blank" rel="noopener noreferrer">
            <img src={whatsapp} alt="WhatsApp" />
          </a>
        </div>
      </WhatsIconContainer>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blogs/:id" element={<BlogDetails />} />
        <Route path="/faq" element={<PrivacyPolicy />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/faq/:FaqId" element={<FaqDetails />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/download-app" element={<DownloadApp />} />
        <Route path="/agent-registration" element={<AgentRegistration />} />
        <Route path="/registration/form" element={<RegistrationForm />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </main>
  );
}

export default App;
